import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'

import rd1 from '../../../Images/rd1.png'
import rd2 from '../../../Images/rd2.png'
import rd3 from '../../../Images/rd3.png'
import rd4 from '../../../Images/rd4.png'
import rd5 from '../../../Images/rd5.png'
import rd6 from '../../../Images/rd6.png'
import rd7 from '../../../Images/rd7.png'
import rd8 from '../../../Images/rd8.png'
import rd9 from '../../../Images/rd9.png'

import rd11 from '../../../Images/rd11.png'
import rd12 from '../../../Images/rd12.png'
import rd13 from '../../../Images/rd13.png'
import rd14 from '../../../Images/rd14.png'

import rd16 from '../../../Images/rd16.png'
import rd17 from '../../../Images/rd17.png'
import rd18 from '../../../Images/rd18.png'
import rd19 from '../../../Images/rd19.png'

class Rdlabs extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Gallery-content">
                            <h1>R & D Labs</h1>

                            <div className="gallery photos-section" data-lightbox="mygallery">
                                <img src={rd1} id="img" alt="R & B Labs"/>
                                <img src={rd2} id="img" alt="R & B Labs"/>
                                <img src={rd3} id="img" alt="R & B Labs"/>
                                <img src={rd4} id="img" alt="R & B Labs"/>
                                <img src={rd5} id="img" alt="R & B Labs"/>
                                <img src={rd6} id="img" alt="R & B Labs"/>
                                <img src={rd7} id="img" alt="R & B Labs"/>
                                <img src={rd8} id="img" alt="R & B Labs"/>
                                <img src={rd9} id="img" alt="R & B Labs"/>
               
                                <img src={rd11} id="img" alt="R & B Labs"/>
                                <img src={rd12} id="img" alt="R & B Labs"/>
                                <img src={rd13} id="img" alt="R & B Labs"/>
                                <img src={rd14} id="img" alt="R & B Labs"/>
         
                                <img src={rd16} id="img" alt="R & B Labs"/>
                                <img src={rd17} id="img" alt="R & B Labs"/>
                                <img src={rd18} id="img" alt="R & B Labs"/>
                                <img src={rd19} id="img" alt="R & B Labs"/>
                            </div>


                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Rdlabs