import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'

import clean1 from '../../../Images/clean1.png'
import clean2 from '../../../Images/clean2.png'
import clean3 from '../../../Images/clean3.png'
import clean4 from '../../../Images/clean4.png'
import clean5 from '../../../Images/clean5.png'
import clean6 from '../../../Images/clean6.png'
import clean7 from '../../../Images/clean7.png'
import clean8 from '../../../Images/clean8.png'
import clean9 from '../../../Images/clean9.png'
import clean10 from '../../../Images/clean10.png'
import clean11 from '../../../Images/clean11.png'
import clean12 from '../../../Images/clean12.png'
import clean13 from '../../../Images/clean13.png'
import clean14 from '../../../Images/clean14.png'

class CleanRooms extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Gallery-content">
                            <h1>Clean Rooms</h1>

                            <div className="gallery photos-section" data-lightbox="mygallery">
                                <img src={clean1} id="img" alt="clean Rooms"/>
                                <img src={clean2} id="img" alt="clean Rooms"/>
                                <img src={clean3} id="img" alt="clean Rooms"/>
                                <img src={clean4} id="img" alt="clean Rooms"/>
                                <img src={clean5} id="img" alt="clean Rooms"/>
                                <img src={clean6} id="img" alt="clean Rooms"/>
                                <img src={clean7} id="img" alt="clean Rooms"/>
                                <img src={clean8} id="img" alt="clean Rooms"/>
                                <img src={clean9} id="img" alt="clean Rooms"/>
                                <img src={clean10} id="img" alt="clean Rooms"/>
                                <img src={clean11} id="img" alt="clean Rooms"/>
                                <img src={clean12} id="img" alt="clean Rooms"/>
                                <img src={clean13} id="img" alt="clean Rooms"/>
                                <img src={clean14} id="img" alt="clean Rooms"/>
                            </div>

                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default CleanRooms