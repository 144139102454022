import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'

import Bio1 from '../../../Images/Bio1.png'
import Bio2 from '../../../Images/Bio2.png'
import Bio3 from '../../../Images/Bio3.png'
import Bio4 from '../../../Images/Bio4.png'
import Bio5 from '../../../Images/Bio5.png'
import Bio6 from '../../../Images/Bio6.png'
import Bio7 from '../../../Images/Bio7.png'
import Bio8 from '../../../Images/Bio8.png'
import Bio9 from '../../../Images/Bio9.png'
import Bio10 from '../../../Images/Bio10.png'
import Bio11 from '../../../Images/Bio11.png'
import Bio12 from '../../../Images/Bio12.png'
import Bio13 from '../../../Images/Bio13.png'
import Bio14 from '../../../Images/Bio14.png'
import Bio15 from '../../../Images/Bio15.png'
import Bio16 from '../../../Images/Bio16.png'
import Bio17 from '../../../Images/Bio17.png'
import Bio18 from '../../../Images/Bio18.png'

class Biotech extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wraper">
                <Header />
                    <div id="page-content">
                        <div className="Gallery-content">
                            <h1>Bio-Tech & Bio-Pharma</h1>
                            <div className="gallery photos-section" data-lightbox="mygallery">
                                <img src={Bio1} id="img" alt="Biotech" />
                                <img src={Bio2} id="img" alt="Biotech" />
                                <img src={Bio3} id="img" alt="Biotech" />
                                <img src={Bio4} id="img" alt="Biotech" />
                                <img src={Bio5} id="img" alt="Biotech" />
                                <img src={Bio6} id="img" alt="Biotech" />
                                <img src={Bio7} id="img" alt="Biotech" />
                                <img src={Bio8} id="img" alt="Biotech" />
                                <img src={Bio9} id="img" alt="Biotech" />
                                <img src={Bio10} id="img" alt="Biotech" />
                                <img src={Bio11} id="img" alt="Biotech" />
                                <img src={Bio12} id="img" alt="Biotech" />
                                <img src={Bio13} id="img" alt="Biotech" />
                                <img src={Bio14} id="img" alt="Biotech" />
                                <img src={Bio15} id="img" alt="Biotech" />
                                <img src={Bio16} id="img" alt="Biotech" />
                                <img src={Bio17} id="img" alt="Biotech" />
                                <img src={Bio18} id="img" alt="Biotech" />
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Biotech