import React, { Component } from 'react'
import './home.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Homebanner1 from '../../Images/Homebanner1.png'
import Homebanner2 from '../../Images/Homebanner2.png'
import Homebanner3 from '../../Images/Homebanner3.png'
import Homebanner4 from '../../Images/Homebanner4.png'
import Calendericon from '../../Images/Calendaricon.svg'
import Projectsicon from '../../Images/Projectsicon.svg'
import Countryicon from '../../Images/Countryicon.svg'

import OtherService from '../../Components/OtherService'
import { Link } from 'react-router-dom'

class Home extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div className="page-content">
                        <div className="main-Header">
                            <h3 className="main-title">End to End solutions for all your Pharma Industry Requirements</h3>
                            <h4 className="caption" style={{fontFamily:"Dancing Script"}}>Since 1993....</h4>
                            <marquee behavior="scroll" direction="left" scrollamount="19" className="Scrolling-text">"M.J. Associates is now converted as MJA PharmaTech Private Limited”.</marquee>
                        </div>

                        <div className="whowe-are-section">
                            <div className="whowe-are-section-content">

                                <div className="rightside-image">
                                    <div id="carouselExampleControls" className="carousel slide whowe-are-image" data-interval="2000" data-ride="carousel">
                                        <div class="carousel-inner">
                                           
                                            <div class="carousel-item active">
                                                <img src={Homebanner2} className="whowe-are-image" alt="Teamphoto"/>
                                            </div>
                                            <div class="carousel-item">
                                                <img src={Homebanner4} className="whowe-are-image" alt="Teamphoto"/>
                                            </div>
                                            <div class="carousel-item">
                                                <img src={Homebanner3} className="whowe-are-image" alt="Teamphoto"/>
                                            </div>
                                           
                                            <div class="carousel-item">
                                                <img src={Homebanner1} className="whowe-are-image" alt="Teamphoto"/>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>


                                <div className="leftside-text">
                                    <h1>Who we <span style={{color:"#64B3F4"}}>are</span></h1>
                                    <p>
                                        MJA PharmaTech Private Limited (earlier known as M.J Associates) is a Technical consultancy Organization operating since 1993 and offering Technical Services in all 
                                        Engineering disciplines relating mainly to the setting up of Pharmaceuticals, Bulk drugs and Drug Intermediates, 
                                         Speciality Chemicals and Food Processing. Operating from our office at Bengaluru, we have successfully executed over a 
                                        hundred projects relating to Formulations in all Dosage Forms, Bulk Drugs or API’s and Intermediates, Pilot Plants 
                                        and Research Laboratories and Food & Confectionery.
                                    </p>
                                    <Link to="/About"><button type="button" className="btn read-more-btn">Read more</button></Link>
                                </div>
                            
                            </div>
                       
                        </div>

                        <div className="overview-section">
                            <div className="overview-cards">
                                <div className="card1">
                                    <img src={Calendericon} alt="icon"/>
                                    <p className="overview-text overview-text2">Since <span style={{color:"#64B3F4"}}>1993</span></p>
                                </div>

                                <div className="card1">
                                    <img src={Projectsicon} alt="icon"/>
                                    <p className="overview-text overview-text2">160 + <span style={{color:"#64B3F4"}}>Projects</span></p>
                                </div>

                                <div className="card1">
                                    <img src={Countryicon} alt="icon"/>
                                    <p className="overview-text">Countrywide <span style={{color:"#64B3F4"}}>Operations</span></p>
                                </div>
                            </div>
                          
                        </div>

                        <div className="Different-services">
                            <OtherService />
                        </div>

                      
                     
                    </div>
                    <Footer />
            </div>
        );
    }
}

export default Home
