import React, { Component } from 'react'
import './associates.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import promoter1 from '../../Images/promoter1.png'
import promoter2 from '../../Images/promoter2.png'
import promoter3 from '../../Images/promoter3.png'

class Associates extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Promotors-section">
                            <h1>Directors</h1>
                            <div className="promoter1">
                                <div class="card mb-3" style={{maxwidth: "540px"}}>
                                    <div class="row no-gutters">
                                        <div class="col-md-4">
                                        <img src={promoter1} class="card-img card-img1" alt="..." />
                                        <h5 class="card-title" style={{marginTop:"20px"}}>A.K MURTHY</h5>
                                        <h6 class="card-title">Marine Engg. College, Calcutta – 1961</h6>
                                        </div>
                                        <div class="col-md-8">
                                        <div class="card-body card-body1">
                                           
                                            <p className="card-text">Over 58 years of experience in Engineering Services comprising Plant Engineering, Projects & Consultancy.</p>
                                            <div className="prof-text">
                                                <p className="card-text">Comprises of experience as Chief Engineer on Foreign going ships, Chief Engineer of Pharmaceutical companies such as Biological E Ltd, Warner Hindustan Ltd & Cipla Ltd.,</p>
                                                <p className="card-text">Consultant with this company since 1993.</p>
                                            </div>
                                           
                                          
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="promoter1">
                                <div class="card mb-3" style={{maxwidth: "540px"}}>
                                    <div class="row no-gutters">
                                        <div class="col-md-4">
                                        <img src={promoter2} class="card-img card-img1" alt="..." />
                                        <h5 class="card-title" style={{marginTop:"20px"}}>S. JANARDHAN</h5>
                                        <h6 className="card-title">B.E. (Mechanical), Mysore University – 1986</h6>
                                        </div>
                                        <div class="col-md-8">
                                        <div class="card-body card-body2">
                                            
                                            <p className="card-text">Over 34 years of experience in Projects & Consultancy.</p>
                                            <div className="prof-text">
                                                   <p className="card-text asso-text">8 years as Mechanical Eng. in M/s. Cipla Ltd., handling plant maintenance at Bangalore Plant and Project Design & Execution at Bangalore & Kurkumbh near Pune.</p>
                                                   <p className="card-text asso-text">Consultant with this company since 1993.</p>
                                            </div>
                                         
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="promoter1">
                                <div class="card mb-3" style={{maxwidth: "540px"}}>
                                    <div class="row no-gutters">
                                        <div class="col-md-4">
                                        <img src={promoter3} class="card-img card-img1" alt="..." />
                                        <h5 class="card-title" style={{marginTop:"20px"}}>SUNDAR D.B</h5>
                                        <h6 className="card-title">B.E. (Mechanical) Bangalore University</h6>
                                        <h6 className="card-title">M. S (T.O) – B.I.T.S Pilani, Rajasthan</h6>
                                        <h6 className="card-title">M.B.A – B.I.T.S Pilani, Rajasthan</h6>
                                        </div>
                                        <div class="col-md-8">
                                        <div class="card-body card-body3">
                                            <p className="card-text">Over 27 years of experience in Engineering Services Design & Project implementation in following companies. </p>
                                            <li className="card-text">2 Yrs. Mechanical Engineer in M/s. Cipla Ltd. Bangalore, India.</li>
                                            <li className="card-text">4 Yrs. Sr. Sales Engineer in M/s. Voltas Ltd., Bangalore, India.</li>
                                            <li className="card-text">5 Yrs. Sr. Sales Engineer in AL Fittaim Engineering, Dubai UAE.</li>
                                            <li className="card-text">5 Yrs. Sales Manager –Flakt Woods, Dubai, UAE.</li>
                                            <li className="card-text">Consultant with this company since 2008.</li>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                       
                        </div>

                        <div className="Associates-section">
                            <h1>Associates</h1>

                            <div className="associates-cards">
                                <div className="card6">
                                    <h1> Mr Andrew Joseph</h1>
                                    <p className="status">D'sinessence<br/> Architect</p>
                                    <p className="web-link">d.sinessence@yahoo.co.in</p>
                                </div>

                                <div className="card6">
                                    <h1>Mr Chandroo Iyer </h1>
                                    <p className="status">Chandroo Iyer Associates<br/> Architect</p>
                                    <p className="web-link">chandroo@ciarchitects.in</p>
                                </div>

                                <div className="card6">
                                    <h1> Mr Vireshkumar Sheth</h1>
                                    <p className="status">Shriyata Lifetech Private Limited<br/> Process consultant</p>
                                    <p className="web-link">viresh.sheth@slpl.life</p>
                                </div>

                                <div className="card6">
                                    <h1>Mr Vishnu Bhat </h1>
                                    <p className="status">GSVB PROBIZ Technology<br/> Process Consultant</p>
                                    <p className="web-link">www.gsvbprobiz.in</p>
                                </div>

                                <div className="card6">
                                    <h1>Mr Sanjay A Purohit </h1>
                                    <p className="status">Studio Archnovate Innovation<br/>Architect</p>
                                    <p className="web-link">www.archnovate.in</p>
                                </div>
                            </div>
                        </div>

                    </div>
                <Footer /> 
            </div>
        );
    }
}

export default Associates
