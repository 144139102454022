import React, { Component} from 'react'
import './careers.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { Link } from 'react-router-dom'



class Careers extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <di id="page-content">
                        <div className="Job-descriptions">

                        <div className="Job2">
                           

                           <h1>JOB PROFILE</h1>
                           <table>
                               <tr>
                                   <td className="left-title">JOB DESIGNATION</td>
                                   <td>Senior Piping Engineer</td>
                               </tr>

                                <tr>
                                    <td className="left-title">JOB DESCRIPTION</td>
                                        <td className="job-dec-points">
                                            <p>
                                                Graduate in Mechanical Engineering from a reputed college or
                                                alternatively Diploma with good practical experience.
                                            </p>
                                            <p className="dots">
                                                Experience of around 5 to 7 yrs minimum preferably with a suitable
                                                consultant / reputable piping contractor in the design of piping systems
                                                mainly in the Pharmaceutical sector, specialty chemical sector or food &
                                                process industries.
                                            </p>
                                            <p className="dots">
                                                Experience in Design of Clean Utilities like: Purified Water Systems,
                                                Water for Injection Systems and Process Piping for Biotech Industries.
                                            </p>
                                            <p className="dots">
                                                Ability to design piping systems with carbon steel, stainless steel, PVC &
                                                other similar materials with adequate knowledge of codes & standards
                                                relating to piping.
                                            </p>
                                            <p className="dots">
                                                Ability to prepare drawings in coordination with other services like:
                                                Electrical distribution systems, HVAC systems etc.,
                                            </p>
                                            <p className="dots">
                                                Fair knowledge of equipment such as boilers, air compressors, chiller
                                                plants, pumps & pumping systems along with internal piping details etc.,
                                            </p>
                                            <p className="dots">
                                                Good leadership qualities and abilities to lead a team.
                                            </p>
                                        </td>
                                </tr>

                               <tr>
                                   <td className="left-title">QUALIFICATION</td>
                                   <td style={{color:"blue",fontWeight:"bold"}}>B.E- MECHANICAL</td>
                               </tr>
                               <tr>
                                   <td className="left-title">EXPERIENCE</td>
                                    <td className="experience" style={{color:"blue",fontWeight:"bold"}}>
                                    Minimum 5 to 7 years in Piping design related to Pharma Chemical &
                                    other processing industry. Knowledge of Piping works related to clean
                                    rooms.
                                    </td>
                               </tr>

                               <tr>
                                   <td className="left-title">NO.OF POSTS</td>
                                   <td style={{color:"blue",fontWeight:"bold"}}>1</td>
                               </tr>
                               <tr>
                                        <td className="left-title">LOCATION</td>
                                        <td style={{color:"blue",fontWeight:"bold"}}>BANGALORE</td>
                                    </tr>
                               

                                    <tr>
                                        <td className="left-title">WORK TIMINGS</td>
                                        <td style={{color:"blue",fontWeight:"bold"}}>9:30 a.m to 6.00 p.m</td>
                                    </tr>
                               
                           </table><br/>
                           <Link to="/Jobform"><button type="button" className="btn btn-primary">Apply Now</button></Link>
                       </div>
                            <div className="Job1">
                               

                                <h1>JOB PROFILE</h1>
                                <table>
                                    <tr>
                                        <td className="left-title">JOB DESIGNATION</td>
                                        <td>Process Engineer - Chemical</td>
                                    </tr>
                                    <tr>
                                        <td className="left-title">JOB DESCRIPTION</td>
                                        <td>
                                            <li>Graduate in Chemical Engineering from a reputed college.</li>
                                            <li>Knowledge of AutoCAD preferred.</li>
                                            <li>Experience on process design with consultancy organization preferred.</li>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="left-title">QUALIFICATION</td>
                                        <td style={{color:"blue",fontWeight:"bold"}}>B.E- CHEMICAL</td>
                                    </tr>
                                    <tr>
                                        <td className="left-title">EXPERIENCE</td>
                                        <td className="experience" style={{color:"blue",fontWeight:"bold"}}>
                                            1 to 2 years’ experience in design of Chemical Process equipment,
                                            preparation of process flow diagram and P&ID, Heat & Mass transfer
                                            calculation etc.,
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="left-title">CAREER GROWTH for the CANDIDATE</td>
                                        <td style={{color:"blue",fontWeight:"bold"}}>
                                            Growth to Senior Project Engineer & Project Coordinator to handle
                                            projects Detailing by heading a team.
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="left-title">NO.OF POSTS</td>
                                        <td style={{color:"blue",fontWeight:"bold"}}>1</td>
                                    </tr>

                                    <tr>
                                        <td className="left-title">LOCATION</td>
                                        <td style={{color:"blue",fontWeight:"bold"}}>BANGALORE</td>
                                    </tr>
                               

                                    <tr>
                                        <td className="left-title">WORK TIMINGS</td>
                                        <td style={{color:"blue",fontWeight:"bold"}}>9:30 a.m to 6.00 p.m</td>
                                    </tr>

                                </table><br/>
                                <Link to="/Jobform"><button type="button" className="btn btn-primary">Apply Now</button></Link>
                            </div>

                       
                        </div>
                    </di>
                <Footer />
            </div>
        );
    }
}

export default Careers