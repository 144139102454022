import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'

import formul1 from '../../../Images/formul1.png'
import formul2 from '../../../Images/formul2.png'
import formul3 from '../../../Images/formul3.png'
import formul4 from '../../../Images/formul4.png'
import formul5 from '../../../Images/formul5.png'
import formul6 from '../../../Images/formul6.png'
import formul7 from '../../../Images/formul7.png'
import formul8 from '../../../Images/formul8.png'
import formul9 from '../../../Images/formul9.png'
import formul10 from '../../../Images/formul10.png'
import formul11 from '../../../Images/formul11.png'
import formul12 from '../../../Images/formul12.png'
import formul13 from '../../../Images/formul13.png'
import formul14 from '../../../Images/formul14.png'
import formul15 from '../../../Images/formul15.png'
import formul16 from '../../../Images/formul16.png'

class Formulation extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Gallery-content">
                            <h1>Formulation</h1>
                            
                            <div className="gallery photos-section" data-lightbox="mygallery">
                                <img src={formul1} id="img" alt="Formulation"/>
                                <img src={formul2} id="img" alt="Formulation"/>
                                <img src={formul3} id="img" alt="Formulation"/>
                                <img src={formul4} id="img" alt="Formulation"/>
                                <img src={formul5} id="img" alt="Formulation"/>
                                <img src={formul6} id="img" alt="Formulation"/>
                                <img src={formul7} id="img" alt="Formulation"/>
                                <img src={formul8} id="img" alt="Formulation"/>
                                <img src={formul9} id="img" alt="Formulation"/>
                                <img src={formul10} id="img" alt="Formulation"/>
                                <img src={formul11} id="img" alt="Formulation"/>
                                <img src={formul12} id="img" alt="Formulation"/>
                                <img src={formul13} id="img" alt="Formulation"/>
                                <img src={formul14} id="img" alt="Formulation"/>
                                <img src={formul15} id="img" alt="Formulation"/>
                                <img src={formul16} id="img" alt="Formulation"/>
                            </div>

                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Formulation