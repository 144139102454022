import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'

import food1 from '../../../Images/food1.jpg'
import food2 from '../../../Images/food2.jpg'
import food3 from '../../../Images/food3.jpg'
import food4 from '../../../Images/food4.jpg'

class Foods extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Gallery-content">
                            <h1>Foods & Confectionery</h1>

                            <div className="gallery photos-section" data-lightbox="mygallery">
                                <img src={food1} id="img" alt="Foods"/>
                                <img src={food2} id="img" alt="Foods"/>
                                <img src={food3} id="img" alt="Foods"/>
                                <img src={food4} id="img" alt="Foods"/>
                            </div>

                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Foods