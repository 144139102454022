import React, { Component } from 'react'
import './services.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'

import service1image from '../../Images/Service1image.svg'
import service2image from '../../Images/Service2image.svg'
import service3image from '../../Images/Service3image.svg'
import service4image from '../../Images/Service4image.svg'
import service5image from '../../Images/Service5image.svg'
import service6image from '../../Images/Service6image.svg'

import workflow from '../../Images/workflow.png'
import Serviceicon1 from '../../Images/Serviceicon1.svg'
import Serviceicon2 from '../../Images/Serviceicon2.svg'
import Serviceicon3 from '../../Images/Serviceicon3.svg'
import Serviceicon4 from '../../Images/Serviceicon4.svg'
import Serviceicon5 from '../../Images/Serviceicon5.svg'
import Serviceicon6 from '../../Images/Serviceicon6.svg'
import Serviceicon7 from '../../Images/Serviceicon7.svg'
import Serviceicon8 from '../../Images/Serviceicon8.svg'

class Services extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div>

                   
                        <div className="services-cards" id="services-sec">
                                <div className="cards">
                                    <div className="card2">
                                        <img src={service1image} class="card-img-top" alt="service-icon"/>
                                        <div class="card-body">
                                            <h5 class="card-title1">Bulk Drugs</h5>
                                            <p class="card-text1">
                                                We provide total design package for setting up a finished facility for manufacture of Sterile and 
                                                Non-Sterile APIs and HPAPIs, Drug Intermediates and Speciality Chemicals, Herbal Extracts and Biotech 
                                                and Biopharma Products.
                                            </p>
                                    
                                        </div>
                                    </div>

                                    <div className="card2">
                                        <img src={service2image} class="card-img-top" alt="service-icon"/>
                                        <div class="card-body">
                                            <h5 class="card-title1">Formulation</h5>
                                            <p class="card-text1">
                                                We have total expertise in facility design for manufacturing plants for Oral Solid Dosage Forms, 
                                                Liquid Orals, Injectables, Semi-Solids, Soft Gel Filling and Nutraceuticals considering all aspects 
                                                of control of cross-contamination.
                                            </p>
                                           
                                        </div>
                                    </div>

                                    <div className="card2">
                                        <img src={service6image} class="card-img-top" alt="service-icon"/>
                                        <div class="card-body">
                                            <h5 class="card-title1">Bio-Tech & Bio-Pharma</h5>
                                            <p class="card-text1">
                                                With the growing trend to focus on research on  medicinal products, nutraceuticals, etc., 
                                                derived from biological sources and the growing applications in biopharma for preparation of 
                                                products using living organisms like bacteria, yeast and mammalian cells, we have been providing 
                                                supporting Engineering services to such Research labs and manufacturing units, comprising Clean rooms 
                                                with stringent air control systems, Bio-safety cabinets, Isolators, etc.
                                            </p>
                                            
                                        </div>
                                    </div>

                              

                                    <div className="card2">
                                        <img src={service4image} class="card-img-top" alt="service-icon"/>
                                        <div class="card-body">
                                            <h5 class="card-title1">R & D Labs</h5>
                                            <p class="card-text1">
                                                We have to our credit successful completion of R and D facilities for Bulk Drugs and Formulation, 
                                                Research Institutions, and labs for special products, incorporating latest technologies in Fume 
                                                hood design and meeting International standards.
                                            </p>
                         
                                        </div>
                                    </div>

                                    <div className="card2">
                                        <img src={service5image} class="card-img-top" alt="service-icon"/>
                                        <div class="card-body">
                                            <h5 class="card-title1">Food & Confectionery</h5>
                                            <p class="card-text1">
                                                In our journey, we have successfully undertaken the design of many such plants for MNC and local clients comprising 
                                                material handling systems, manufacturing and high speed packing lines.
                                            </p>
                                           
                                        </div>
                                    </div>

                                    <div className="card2">
                                        <img src={service3image} class="card-img-top" alt="service-icon"/>
                                        <div class="card-body">
                                            <h5 class="card-title1">Clean Rooms</h5>
                                            <p class="card-text1">
                                                This is the core of all activities connected with Pharma facility. It comprises of design of Clean room 
                                                infrastructure and equipment, meeting the latest standards of HVAC design and controls along with Clean room validation 
                                                assistance to meet Regulatory expectations.
                                            </p>
                                 
                                        </div>
                                    </div>

                               
                                </div>
                  
                        </div>

                        <div className="Workflow-process">
                            <h1>Workflow <span style={{color:"#64B3F4"}}>Process</span></h1>
                            <img src={workflow}  className="workflow-image" alt="workflow-chart"/>
                        </div>

                        <div className="Workflow-desc">
                            <div className="workflow-content">
                                <div className="card3">
                                    <div className="wl-image">
                                        <img src={Serviceicon1} alt="vector"/>
                                        <h1>Concept Design</h1>
                                    </div>
                                    <div className="wr-text">
                                        <p>
                                            Based on preliminary data obtained from the client, closely study the same and provide conscious, 
                                            sustainable and tailor-made designs based on the type of project.  Explain the options possible and 
                                            guide the client in selecting the most favorable options.  This will be a base document to carry forward. 
                                        </p>
                                    </div>
                                </div>

                                <div className="card3">
                                    <div className="wr-image">
                                        <img src={Serviceicon2} alt="vector"/>
                                        <h1>Master Planning</h1>
                                    </div>
                                    <div className="wl-text">
                                        <p>
                                            Based on initial inputs received, advice on the site selection, production processes available, 
                                            GMP requirements, men and material flows, warehousing requirements, segregations to be followed 
                                            and regulatory compliances achievable.
                                        </p>
                                    </div>
                                </div>

                                <div className="card3">
                                    <div className="wl-image">
                                        <img src={Serviceicon3} alt="vector"/>
                                        <h1>Basic Engineering</h1>
                                    </div>
                                    <div className="wr-text">
                                        <p>
                                            List out the functional requirement of the project, fit in the process end users requirement, 
                                            provide cost effective solutions in preparation of basic layouts, process flow diagrams, 
                                            piping and instrumentation diagrams, control philosophy to be followed, equipment sizing to meet 
                                            the requirements, equipment specifications etc., along with budgetary costing.
                                        </p>
                                    </div>
                                </div>

                                <div className="card3">
                                    <div className="wr-image">
                                        <img src={Serviceicon4} alt="vector"/>
                                        <h1>Detailed Engineering</h1>
                                    </div>
                                    <div className="wl-text detailed-design">
                                        <p>
                                            Preparation of detailed specifications,tender documents, bill of quantities and tender drawings, request for quotations, technical bid analysis,
                                            user requirement specifications for process and utility equipment, 
                                            detailed specifications for individual disciplines like layouts and clean rooms, HVAC systems, Piping 
                                            System for both utility and process, Electrical System for HT and LT, LV Systems etc.
                                        </p>
                                    </div>
                                </div>

                                <div className="card3">
                                    <div className="wl-image">
                                        <img src={Serviceicon6} alt="vector"/>
                                        <h1>Tendering Process</h1>
                                    </div>
                                    <div className="wr-text detailed-design">
                                        <p>
                                            Issue of Tender documents complete with technical and commercial details, bill of quantities and tender drawings to 
                                            selected vendors / contractors, review the bids with technical bid analysis and assist client in selection of 
                                            appropriate vendor / contractor.
                                        </p>
                                    </div>
                                </div>

                                <div className="card3">
                                    <div className="wr-image">
                                        <img src={Serviceicon5} alt="vector"/>
                                        <h1>Project Excecution</h1>
                                    </div>
                                    <div className="wl-text">
                                        <p>
                                            Follow ups during project execution by scrutiny of Good for construction and shop drawings, submittals 
                                            received and site visits to ensure proper quality of work at site with proper co-ordination among agencies.
                                        </p>
                                    </div>
                                </div>

                                <div className="card3">
                                    <div className="wl-image">
                                        <img src={Serviceicon7} alt="vector"/>
                                        <h1>Commissioning support</h1>
                                    </div>
                                    <div className="wr-text">
                                        <p>
                                            Supporting client with Installation qualification documents, assist in mechanical commissioning of major 
                                            equipment and systems and ensuring receipt of all relevant documents at site.
                                        </p>
                                    </div>
                                </div>


                                <div className="card3">
                                    <div className="wr-image">
                                        <img src={Serviceicon8} alt="vector"/>
                                        <h1>Validation support</h1>
                                    </div>
                                    <div className="wl-text">
                                        <p>
                                            Provide assistance during Operational qualification by checking that all equipment and systems installed and 
                                            ready for testing as per validated protocol and checking reports generated during trial runs and clearing the same.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                   

                      
                    </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Services
