import React, { Component } from 'react'
import './about.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Aboutbanner from '../../Images/Aboutbanner.svg'
import key1 from '../../Images/key1.svg'
import key2 from '../../Images/key2.svg'
import key3 from '../../Images/key3.svg'

class About extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="about-banner">
                            <img src={Aboutbanner} className="Aboutbanner" alt="banner"/>
                         </div>

                         <div className="aboutus-text">
                            <h1 className="aboutus-header">About <span style={{color:"#64B3F4"}}>Us</span></h1>
                            <p style={{marginTop:"2%"}}>
                                MJA PharmaTech Private Limited (earlier known as M.J.Associates) is a Technical consultancy Organization operating since 1993 and offering Technical Services in all 
                                Engineering disciplines relating mainly to the setting up of Pharmaceuticals, Bulk drugs and Drug Intermediates, 
                                Speciality Chemicals and Food Processing. Operating from our office at Bengaluru, we have successfully executed over a 
                                hundred projects relating to Formulations in all Dosage Forms, Bulk Drugs or API’s and Intermediates, Pilot Plants 
                                and Research Laboratories and Food & Confectionery.
                            </p><br/>
                            <p>
                                We have a team of competent Engineers experienced in various disciplines connected with setting up of Pharmaceutical plants, 
                                Clean Rooms, HVAC designs, Piping, Electrical and LV systems. We have a strong record in designing and 
                                executing R&D laboratories for major clients.
                            </p><br/>
                            <p>
                                The company was promoted by a group of Engineers with a long record of service in Pharmaceutical 
                                plants both in plant Engineering and project Engineering. Over the years, many of the facilities designed by 
                                us have been inspected and cleared by regulatory agencies such as USFDA, UKMHRA, TGA Australia, EU, WHO.
                            </p>
                         </div>

                         <div className="keypoint-section">
                            <div className="keypoint-content">
                                <div className="first-key">
                                    <div className="leftkey-text">
                                        <h1>Vision</h1>
                                        <p>
                                            To be the preferred choice of the Pharmaceutical Industry in the field of Engineering consultancy and 
                                            to continually upgrade the skills of our employees towards achieving this.
                                        </p>
                                    </div>
                                    <div className="rightkey-image">
                                        <img src={key1} className="rightkey-image1" alt="Service key"/>
                                    </div>
                                </div>

                                <div className="second-key">
                                    <div className="rightkey-text">
                                        <h1>Mission</h1>
                                        <p>
                                            To closely work with the client in developing and providing quality design work to meet acceptance standards. 
                                            To provide a competitive edge to our clients with our innovative designs and quality service and build a 
                                            healthy relationship with each client. To have the highest degree of integrity, professionalism and ethics 
                                            in discharge of our duties.
                                        </p>
                                    </div>
                                    <div className="leftkey-image">
                                        <img src={key2} className="leftkey-image1" alt="service-key"/>
                                    </div>
                                </div>
                            </div>
                         </div>

                        <div className="third-key">
                            <img src={key3} className="key3" alt="service-key"/>
                        </div>

                        <div className="code-of-ethics">
                            <h1>Code of Ethics</h1>

                            <p style={{marginTop:"4%"}}>
                                Accept responsibility to society and to the profession of consulting services.
                            </p>
                            <p>
                                Look for solutions confirming to principles of sustainable development.
                            </p>
                            <p>
                                Uphold the dignity, standing and reputation of the consulting industry while carrying out our services.
                            </p>
                            <p>
                                Maintain the knowledge and skills at the highest levels consistent with the developments in technology, 
                                legislation and management.
                            </p>
                            <p>
                                Ensure utmost care and diligence in all services rendered to the client.
                            </p>
                            <p>
                                Act at all times in the legitimate interest of the client by providing all services with full integrity and faithfulness.
                            </p>
                            <p>
                                Ensure confidentiality of any secrecy agreement entered into with the client.
                            </p>
                            <p>
                                Be impartial at all times in providing professional advice, judgement or decision.
                            </p>
                            <p>
                                Inform client of any potential conflict of interest that may arise while performing our services.
                            </p>
                            <p>
                                Not accept any remuneration which will prejudice our independent judgement.
                            </p>
                            <p>
                                Always promote concept of quality-based selection without injuring the reputation or business of others.
                            </p>
                            <p>
                                In the event of being officially asked to review the work of another consultant, behave in accordance with appropriate conduct
                                and  courtesy without harming the professional reputation of any fellow consultant.
                            </p>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default About
