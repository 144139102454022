import React, { Component } from 'react'
import './contact.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import contactvector from '../../Images/contactvector.png'
import Mapvector from '../../Images/Mapvector.png'

import axios from 'axios'

class Contact extends Component{
   
    state = {
        name: '',
        number: '',
        email: '',
        topic: '',
        sent: false
    }



//handleChange

    handlenameChange = (event) => {
        this.setState({
            name:event.target.value
        })
    }

    handlenumberChange = (event) => {
        this.setState({
            number:event.target.value
        })
    }

    handleemailChange = (event) => {
        this.setState({
            email:event.target.value
        })
    }

    handletopicChange = (event) => {
        this.setState({
            topic:event.target.value
        })
    }
    
//handleSubmit
handleSubmit = (event) => {
    event.preventDefault();


    let data = {
        name:this.state.name,
        number:this.state.number,
        email:this.state.email,
        topic:this.state.topic
    }

    axios.post('/api/forma',data)
    .then(res=>{
        this.setState({
            sent:true,
        },this.resetForm())
    })
    .catch(()=>{
        console.log('message not sent');
    })
}

//for reseting initial data
resetForm=()=>{
    this.setState({
        name:'',
        number:'',
        email:'',
        topic:''
    })

    setTimeout(()=>{
        this.setState({
            sent:false,
        })
    },3000)
}


    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="contactpage-content">
                            <div>
                                <img src={contactvector} alt="vector"/>
                            </div>
                            <div className="Contact-details">
                                <div className="row contact-div">
                                    <div className="col-md-6">
                                        <form  name="contact" method="post"  data-netlify="true" className="contact-form">
                                            <input type="hidden" name="form-name" value="contact" />
                                                <p>
                                                    <input type="text"  name="name" placeholder="Name" required/> 
                                                </p>
                                                <p>
                                                    <input type="number" name="number" placeholder="Mobile no." required/>
                                                </p>
                                                <p>
                                                    <input type="email"  name="email" placeholder="Email Address" required/>
                                                </p>
                                                <p>
                                                    <select name="[role]" className="drop-down contact-dropdown">
                                                        <option value="">Service you are looking for</option>
                                                        <option value="Bulk Drugs">Bulk Drugs</option>
                                                        <option value="Formulation">Formulation</option>
                                                        <option value="Clean Rooms">Clean Rooms</option>
                                                        <option value="R & D Labs">R & D Labs</option>
                                                        <option value="Foods & Confectionery">Foods & Confectionery</option>
                                                        <option value="Office Interiors">Office Interiors</option>
                                                    </select>
                                                </p>
                                                
                                                <p>
                                                    <button type="submit" className="btn submit-btn">SUBMIT</button>
                                                </p>
                                        </form>
                                    </div>

                                    <div className="col-md-6">
                                    <a href="https://goo.gl/maps/GZkWX1XfkoaZpENZ8" target="_blank"><img src={Mapvector} className="contact-vector" alt="map vector"/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Contact