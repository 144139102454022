import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'

import office1 from '../../../Images/office1.png'
import office2 from '../../../Images/office2.png'
import office3 from '../../../Images/office3.png'
import office4 from '../../../Images/office4.png'
import office5 from '../../../Images/office5.png'
import office6 from '../../../Images/office6.png'
import office7 from '../../../Images/office7.png'
import office8 from '../../../Images/office8.png'
import office9 from '../../../Images/office9.png'
import office10 from '../../../Images/office10.png'
import office11 from '../../../Images/office11.png'
import office12 from '../../../Images/office12.png'
import office13 from '../../../Images/office13.png'
import office14 from '../../../Images/office14.png'
import office15 from '../../../Images/office15.png'
import office16 from '../../../Images/office16.png'
import office17 from '../../../Images/office17.png'
import office18 from '../../../Images/office18.png'
import office19 from '../../../Images/office19.png'
import office20 from '../../../Images/office20.png'
import office21 from '../../../Images/office21.png'

class Officeinter extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Gallery-content">
                            <h1>Office Interiors</h1>

                            <div className="gallery photos-section" data-lightbox="mygallery">
                                <img src={office1} id="img" alt="officeimg"/>
                                <img src={office2} id="img" alt="officeimg"/>
                                <img src={office3} id="img" alt="officeimg"/>
                                <img src={office4} id="img" alt="officeimg"/>
                                <img src={office5} id="img" alt="officeimg"/>
                                <img src={office6} id="img" alt="officeimg"/>
                                <img src={office7} id="img" alt="officeimg"/>
                                <img src={office8} id="img" alt="officeimg"/>
                                <img src={office9} id="img" alt="officeimg"/>
                                <img src={office10} id="img" alt="officeimg"/>
                                <img src={office11} id="img" alt="officeimg"/>
                                <img src={office12} id="img" alt="officeimg"/>
                                <img src={office13} id="img" alt="officeimg"/>
                                <img src={office14} id="img" alt="officeimg"/>
                                <img src={office15} id="img" alt="officeimg"/>
                                <img src={office16} id="img" alt="officeimg"/>
                                <img src={office17} id="img" alt="officeimg"/>
                                <img src={office18} id="img" alt="officeimg"/>
                                <img src={office19} id="img" alt="officeimg"/>
                                <img src={office20} id="img" alt="officeimg"/>
                                <img src={office21} id="img" alt="officeimg"/>
                                

                            </div>


                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Officeinter