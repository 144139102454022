import React, { Component } from 'react'
import './projects.css'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import client1 from '../../Images/client1.jpg'
import client2 from '../../Images/client2.jpg'
import client3 from '../../Images/client3.png'
import client4 from '../../Images/client4.png'
import client5 from '../../Images/client5.png'
import client6 from '../../Images/client6.jpg'
import client7 from '../../Images/client7.jpg'
import client8 from '../../Images/client8.png'
import client9 from '../../Images/client9.png'
import client11 from '../../Images/client11.jpeg'
import client12 from '../../Images/client12.jpeg'
import client15 from '../../Images/client15.jpeg'

import client17 from '../../Images/client17.jpeg'
import client18 from '../../Images/client18.jpeg'
import client20 from '../../Images/client20.jpeg'
import client21 from '../../Images/client21.jpeg'
import client22 from '../../Images/client22.jpeg'
import client23 from '../../Images/client23.jpeg'
import client24 from '../../Images/client24.jpeg'
import client25 from '../../Images/client25.jpeg'
import client26 from '../../Images/client26.jpeg'
import client27 from '../../Images/client27.jpeg'
import client30 from '../../Images/client30.jpeg'
import client31 from '../../Images/client31.jpeg'
import client32 from '../../Images/client32.jpeg'
import client34 from '../../Images/client34.jpeg'
import client35 from '../../Images/client35.jpeg'
import client36 from '../../Images/client36.jpeg'
import client37 from '../../Images/client37.jpg'
import client38 from '../../Images/client38.jpg'

import c1 from '../../Images/c1.png'
import c2 from '../../Images/c2.png'
import c3 from '../../Images/c3.png'
import c4 from '../../Images/c4.png'
import c5 from '../../Images/c5.png'
import c6 from '../../Images/c6.png'
import c7 from '../../Images/c7.png'
import c8 from '../../Images/c8.png'
import c9 from '../../Images/c9.png'
import c10 from '../../Images/c10.png'
import c11 from '../../Images/c11.png'
import c12 from '../../Images/c12.png'
import c13 from '../../Images/c13.png'
import c14 from '../../Images/c14.png'
import c15 from '../../Images/c15.png'
import c16 from '../../Images/c16.png'
import c17 from '../../Images/c17.png'
import c18 from '../../Images/c18.png'
import c19 from '../../Images/c19.png'
import c20 from '../../Images/c20.png'
import c21 from '../../Images/c21.png'
import c22 from '../../Images/c22.png'
import c23 from '../../Images/c23.png'
import c24 from '../../Images/c24.png'
import c25 from '../../Images/c25.png'
import c26 from '../../Images/c26.png'
import c27 from '../../Images/c27.png'
import c28 from '../../Images/c28.png'
import c29 from '../../Images/c29.png'
import c30 from '../../Images/c30.png'
import c31 from '../../Images/c31.png'
import c32 from '../../Images/c32.png'
import c33 from '../../Images/c33.png'
import c34 from '../../Images/c34.png'
import c35 from '../../Images/c35.png'


class Projects extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div is="page-content">
                        <div className="Clients-logos">
                            <h1>Clients</h1>
                            <div className="client-images">
                                <img src={client11} alt="clientimage"/>
                                <img src={client26} alt="clientimage"/>
                                <img src={client25} alt="clientimage"/>
                                <img src={client37} alt="clientimage"/>
                                <img src={client24} alt="clientimage"/>
                                <img src={client23} alt="clientimage"/>
                                <img src={client22} alt="clientimage"/>
                                <img src={client21} alt="clientimage"/>
                                <img src={client38} alt="clientimage"/>
                                <img src={client20} alt="clientimage"/>
                                <img src={client8} alt="clientimage"/>
                                <img src={client1} alt="clientimage" />
                                <img src={client2} className="client2" alt="clientimage" />
                                <img src={client3} alt="clientimage" />
                                <img src={client4} alt="clientimage" />
                                <img src={client5} alt="clientimage"/>
                                <img src={client6} alt="clientimage"/>
                                <img src={client7} alt="clientimage"/>
                                <img src={client9} alt="clientimage"/>
                                <img src={client12} alt="clientimage"/>
        
                                <img src={client15} className="client15" alt="clientimage"/>
       
                                <img src={client17} alt="clientimage"/>
                                <img src={client18} alt="clientimage"/>
             
                                <img src={client27} className="client15" alt="clientimage"/>
                             
                             
                                <img src={client30} className="client15" alt="clientimage"/>
                                <img src={client31} className="client15" alt="clientimage"/>
                                <img src={client32} className="client15" alt="clientimage"/>
                          
                                <img src={client34} className="client15" alt="clientimage"/>
                                <img src={client35} alt="clientimage"/>
                                <img src={client36} alt="clientimage"/>

                                <img src={c1} alt="clientimage" />
                                <img src={c2} alt="clientimage" />
                                <img src={c3} className="client15" alt="clientimage" />
                                <img src={c4} className="client15" alt="clientimage" />
                                <img src={c5} alt="clientimage" />
                                <img src={c6} alt="clientimage" />
                                <img src={c7} alt="clientimage" />
                                <img src={c8} alt="clientimage" />
                                <img src={c9} alt="clientimage" />
                                <img src={c10} alt="clientimage" />
                                <img src={c11} alt="clientimage" />
                                <img src={c12} className="client15" alt="clientimage" />
                                <img src={c13} className="client15" alt="clientimage" />
                                <img src={c14} className="client15" alt="clientimage" />
                                <img src={c15} alt="clientimage" />
                                <img src={c16} alt="clientimage" />
                                <img src={c17} alt="clientimage" />
                                <img src={c18} className="client15" alt="clientimage" />
                                <img src={c19} alt="clientimage" />
                                <img src={c20} alt="clientimage" />
                                <img src={c21} className="client15" alt="clientimage" />
                                <img src={c22} alt="clientimage" />
                                <img src={c23} className="client15" alt="clientimage" />
                                <img src={c24} alt="clientimage" />
                                <img src={c25} alt="clientimage" />
                                <img src={c26} alt="clientimage" />
                                <img src={c27} alt="clientimage" />
                                <img src={c28} alt="clientimage" />
                                <img src={c29} alt="clientimage" />
                                <img src={c30} alt="clientimage" />
                                <img src={c31} className="client15" alt="clientimage" />
                                <img src={c32} alt="clientimage" />
                                <img src={c33} alt="clientimage" />
                                <img src={c34} alt="clientimage" />
                                <img src={c35} alt="clientimage" />




                            </div>
                        </div>

                        <div className="client-list">
                            <h1>Client List</h1>
                            <div className="row client-rows">
                            <div className="col-md-3 first-column">
                                <h3 style={{fontWeight:"bold"}}>BULK DRUGS AND CHEMICALS.</h3>
                                <p> Acebright (India) Pharma Pvt. Ltd</p>
                                <p> Anthem Biosciences India Ltd.</p>
                                <p> Ashirvad Pipes Pvt Ltd (Solvent Adhesives)</p>
                                <p> Apotex Pharmachem (I) Pvt Ltd</p>
                                <p> Biocon Ltd.</p>
                                <p> Bio-Gen Extracts Pvt. Ltd.</p>
                                <p> Cipla Ltd</p>
                                <p> Gland Chemicals Ltd.</p>
                                <p> Hikal Ltd.,</p>
                                <p> Indena India (Plant Extracts).</p>
                                <p> Nesso. (Plant Extract)</p>
                                <p> Olive Life Sciences (Plant Extract).</p>
                                <p> Pact Labs.</p>
                                <p> PSK Pharma Ltd. Russia (Biotech)</p>
                                <p> Sami Chemicals (Plant Extracts)</p>
                                <p> Sequent Scientific.</p>
                                <p> PT Sea Six Energy</p>
                                <p> Syngene International Ltd</p>
                                <p> Tenshi Life Sciences (Biotech)</p>
                            </div>

                            <div className="col-md-3 second-column"> 
                                <h3 style={{fontWeight:"bold"}}>FORMULATIONS.</h3>
                                <p> Alder Biochem Private Limited - (Soft Gel)</p>
                                <p> Aleor Dermaceuticals Limited – (Semisolids and Shampoos)</p>
                                <p> Anglo French Drugs & Industries Ltd. (OSDs)</p>
                                <p> Aurobindo Pharma Ltd (OSDs)</p>
                                <p> Banner Pharmacaps (I) Pvt Ltd (Soft Gel and Tablets)</p>
                                <p> Bharat Biotech Ltd (Biotech, Vaccines)</p>
                                <p> Biokem International Ltd. (Kemwell Group) (Semisolids, Sterile and Non-Sterile).</p>
                                <p> BPREX Pharma Packaging India Pvt Ltd</p>
                                <p> B.P.R.L</p>
                                <p> British Biologicals (Nutraceutical Powders).</p>
                                <p> Brown & Burk Pharmaceuticals Ltd (OSDs And Liquid Orals).</p>
                                <p> Cipla Ltd, Indore (OSDs).</p>
                                <p> Encube Ethicals Ltd (Semisolids)</p>
                                <p> Eucare Pharmaceuticals (P) Ltd</p>
                                <p> Fourrts (India) Laboratories Ltd (OSDs, Liquids and Semisolids).</p>
                                <p> Gland Pharma Ltd (Parenterals Including Prefilled Syringes).</p>
                                <p> Global Remedies Ltd (OSDs and Liquid Orals).</p>
                                <p> Glochem Industries Ltd (OSDs).</p>
                                <p> Group Pharma (Semisolids and Liquids)</p>
                                <p> GSK Pharma</p>
                                <p> Juggat Pharma (OSDs And Liquid Orals)</p>
                                <p> Kemwell Ltd. (OSDs And Liquid Orals)</p>
                                <p> Karnataka Antibiotics & Pharmaceuticals Limited (OSDs And Dry Powder Parenterals)</p>
                                <p> Optimus Generics Private Ltd (OSDs).</p>
                                <p> Radella Holdings (Parenterals)</p>
                                <p> Remedex (OSDs And Liquid Orals)</p>
                                <p> Sarvotam Health Care (Nutraceutical Powders and Semisolids)</p>
                                <p> Shree Anand Life Sciences (Liquid Parenterals And Dry Powder Parenterals)</p>
                                <p> Strides Emerging Markets Private Ltd (OSDs)</p>
                                <p> Tejkamal Pharmaceuticals Pvt Ltd (Liquid Orals)</p>
                                <p> Twenty First Century Pharmaceuticals Ltd (OSDs And Dry Syrup).</p>
                                <p> Warner Lambert Ltd (Confectionery – Candy and Chewing Gum)</p>
                                <p> Wintac Ltd. (Liquid Injectables And OSDs)</p>
                                <p> Wrigley Pvt Ltd. (Confectionery- Chewing Gum)</p>
                               
                            </div>

                            <div className="col-md-3 third-column">
                                <h3 style={{fontWeight:"bold"}}>R AND D LABS</h3>
                                <p> Apotex Research Pvt Ltd.</p>
                                <p> Ashoka University.</p>
                                <p> Aurigene Discovery Technologies Limited</p>
                                <p> Axon Interconnectors & Wires Pvt Ltd (Clean Room Design).</p>
                                <p> Baker Hughes.</p>
                                <p> Beckman Coulter Inc.</p>
                                <p> Cadila Pharmaceuticals Ltd.</p>
                                <p> Central University of Tamil Nadu.</p>
                                <p> Cipla Ltd - ADL.</p>
                                <p> Cookson India Ltd (Electronics).</p>
                                <p> Dr. Reddys Laboratories Ltd. (Chemicals)</p>
                                <p> Dr. Reddys Laboratories Ltd (IPDO).</p>
                                <p> Performance Specialty Products (India) Private Limited - Dupont Hyderabad.</p>
                                <p> Eurofins Advinus Biopharma Services India Pvt Ltd.</p>
                                <p> FMC India.</p>
                                <p> Indian Institute of Science Labs.</p>
                                <p> Indian Institute of Science Education and Research (IISER) - Pune </p>
                                <p> Indian Institute of Science Education and Research (IISER) - Bhopal</p>
                                <p> Invitrogen Ltd.</p>
                                <p> Jubilant Discovery.</p>
                                <p> Kemwell Biopharma Pvt. Ltd.,</p>
                                <p> L’OREAL.</p>
                                <p> Marmon Food Service Technologies LLC.</p>
                                <p> MRPL Ltd.,</p>
                                <p> Mylan Laboratories Limited - R and D Centre.</p>
                                <p> Monsanto Research.</p>
                                <p> Otsuka Pharma.</p>
                                <p> Procter & Gamble Home Products Ltd - H.C.</p>
                                <p> Stelis Biopharma Pvt Ltd.</p>
                                <p> Syngene International Ltd.</p>
                                <p> Ticel Biotech Ltd.</p>
                                <p> Unichem Laboratories Ltd.</p>
                            </div>

                            <div className="col-md-3 fourth-column">
                                <h3 style={{fontWeight:"bold"}}>HOSPITALS, HEALTHCARE AND OTHERS</h3>
                                <p> Gunasheela Maternity & Surgical Hospital - IVF Unit.</p>
                                <p> Baptist Hospital.</p>
                                <p> Healthcare Global Enterprises Limited.</p>
                                <p> ITC Infotech India Ltd. (Interiors and Services)</p>
                                <p> Vienni Training & Consulting LLP.</p>
                                <p> M/s. Mylan Laboratories Ltd., Warehouse.</p>
                            </div>

                           </div>
                        </div>



                    </div>
                <Footer />
            </div>  
        );
    }
}

export default  Projects
