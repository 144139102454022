import React, { Component } from 'react'
import './otherservice.css'
import Topimage from '../../Images/Topimage.png'
import Bottomimage from '../../Images/Bottomimage.png'
import Service1image from '../../Images/Service1image.svg'
import Service2image from '../../Images/Service2image.svg'
import Service3image from '../../Images/Service3image.svg'
import Service4image from '../../Images/Service4image.svg'
import Service5image from '../../Images/Service5image.svg'
import Service6image from '../../Images/Service6image.svg'

import no1 from '../../Images/no1.png'
import no2 from '../../Images/no2.png'
import no3 from '../../Images/no3.png'
import no4 from '../../Images/no4.png'
import no5 from '../../Images/no5.png'
import no6 from '../../Images/no6.png'

import client11 from '../../Images/client11.jpeg'
import client26 from '../../Images/client26.jpeg'
import client25 from '../../Images/client25.jpeg'
import client37 from '../../Images/client37.jpg'
import client24 from '../../Images/client24.jpeg'
import client23 from '../../Images/client23.jpeg'
import client22 from '../../Images/client22.jpeg'
import client21 from '../../Images/client21.jpeg'
import client38 from '../../Images/client38.jpg'

import c1 from '../../Images/c1.png'
import c2 from '../../Images/c2.png'
import c3 from '../../Images/c3.png'
import c4 from '../../Images/c4.png'
import c5 from '../../Images/c5.png'
import c6 from '../../Images/c6.png'
import c7 from '../../Images/c7.png'
import c8 from '../../Images/c8.png'
import c9 from '../../Images/c9.png'
import c10 from '../../Images/c10.png'
import c11 from '../../Images/c11.png'
import c12 from '../../Images/c12.png'
import c13 from '../../Images/c13.png'
import c14 from '../../Images/c14.png'
import c15 from '../../Images/c15.png'
import c16 from '../../Images/c16.png'
import c17 from '../../Images/c17.png'






import { HashLink as Link } from 'react-router-hash-link'

import anglevector from '../../Images/anglevector.png'


class OtherService extends Component{
    render() {
        return(
            <div className="other-service-section">
                <div className="first-part">
                    <img src={Topimage} className="top-image" alt="background-vector"/>
                    <div className="first-aprt-content">
                        <div className="fs-part">
                            <div className="left-content">
                                <img src={no1} className="no1" alt="number-icon"/>
                                <div className="left-content-text">
                                    <h1>Bulk Drugs</h1>
                                    <p>
                                        We provide total design package for setting up<br/> a finished facility
                                    </p>
                                <Link to="/Services#services-sec"><button type="button" className="btn left-btn">Explore More</button></Link>
                                </div>
                              
                            </div>

                            <div className="right-image">
                                <img src={Service1image} alt="service-icon"/>
                            </div>
                        </div>
                      
                    </div>
                        
                    <div className="first-aprt-content2">
                        <div className="fs-part">
                        <div className="right-content">
                            <img src={no2} className="no2" alt="number-icon"/>
                            <h1>Formulation</h1>
                            <p>
                            We have total expertise in facility design for <br/> manufacturing plants
                            </p>
                            <Link to="/Services#services-sec"><button type="button" className="btn right-btn">Explore More</button></Link>
                        </div>

                        <div className="left-image">
                            <img src={Service2image} alt="service-icon"/>
                        </div>
                        </div>
                 
                    </div>

                    <div className="first-aprt-content3">
                        <div className="fs-part">
                            <div className="left-content">
                                <img src={no3} className="no1" alt="number-icon"/>
                                <div className="left-content-text">
                                <h1>Clean Rooms</h1>
                                <p>
                                    This is the core of all activities connected with <br/> Pharma facility design.
                                </p>
                                <Link to="/Services#services-sec"><button type="button" className="btn left-btn">Explore More</button></Link>
                                </div>
                            </div>

                            <div className="right-image">
                                <img src={Service3image} alt="service-icon"/>
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <img src={anglevector} className="angle-vector"/>
                    </div> */}
                    <div className="first-aprt-content4">
                        <div className="fs-part">
                        <div className="right-content">
                            <img src={no4} className="no2" alt="number-icon"/>
                            <h1>R & D Labs</h1>
                            <p>
                                We have to our credit successful completion of<br/> R and D facilities
                            </p>
                            <Link to="/Services#services-sec"><button type="button" className="btn right-btn">Explore More</button></Link>
                        </div>

                        <div className="left-image">
                            <img src={Service4image} alt="service-icon"/>
                        </div>
                        </div>
                    </div>

                    <div className="first-aprt-content5">
                        <div className="fs-part">
                            <div className="left-content">
                                <img src={no5} className="no1" alt="number-icon"/>
                                <div className="left-content-text">
                                <h1>Foods & Confectionery</h1>
                                <p>
                                    We have to our credit the design of such facility <br/> for many MNC and local clients
                                </p>
                                <Link to="/Services#services-sec"><button type="button" className="btn left-btn">Explore More</button></Link>
                                </div>
                            </div>

                            <div className="right-image">
                                <img src={Service5image} alt="service-icon"/>
                            </div>
                        </div>
                    </div>

                    <div className="first-aprt-content4">
                        <div className="fs-part">
                        <div className="right-content">
                            <img src={no6} className="no2" alt="number-icon"/>
                            <h1>Bio-Tech & Bio-Pharma</h1>
                            <p>
                                With the growing trend to focus on research on<br/>
                                medicinal products, nutraceuticals, etc., 
                            </p>
                            <Link to="/Services#services-sec"><button type="button" className="btn right-btn">Explore More</button></Link>
                        </div>

                        <div className="left-image">
                            <img src={Service6image} alt="service-icon"/>
                        </div>
                        </div>
                    </div>

                    <img src={Bottomimage} className="bottom-image" alt="background-vector"/>
                    <div className="Clients-sections">
                            <h1 style={{fontFamily: "Poppins"}} className="client-header">Clients</h1>
                            <div className="wrapper">
                            <div class="slider1">
                                 <div class="slide1">
                                    <img src={client11} alt="client-logo"/>
                                    <img src={client26} alt="client-logo"/>
                                    <img src={client25} alt="client-logo"/>
                                    <img src={client37} alt="client-logo"/>
                                    <img src={client24} alt="client-logo"/>
                                    <img src={client23} alt="client-logo"/>
                                    <img src={client22} alt="client-logo"/>
                                    <img src={client21} alt="client-logo"/>
                                    <img src={client38} alt="client-logo"/>
                                    <img src={c1} alt="client-logo"/>
                                    <img src={c2} alt="client-logo"/>
                                    <img src={c3} alt="client-logo"/>
                                    <img src={c4} alt="client-logo"/>
                                    <img src={c5} alt="client-logo"/>
                                    <img src={c6} alt="client-logo"/>
                                    <img src={c7} alt="client-logo"/>
                                    <img src={c8} alt="client-logo"/>
                                    <img src={c9} alt="client-logo"/>
                                    <img src={c10} alt="client-logo"/>
                                    <img src={c11} alt="client-logo"/>
                                    <img src={c12} alt="client-logo"/>
                                    <img src={c13} alt="client-logo"/>
                                    <img src={c14} alt="client-logo"/>
                                    <img src={c15} alt="client-logo"/>
                                    <img src={c16} alt="client-logo"/>
                                    <img src={c17} alt="client-logo"/>
                                </div> 
                            </div>
                            </div>
                        </div>
                    
                </div>
            </div>
        );
    }
}

export default OtherService