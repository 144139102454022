import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { HashRouter, Switch, Route } from 'react-router-dom'
import './App.css';

import Home from './Screens/Home'
import About from './Screens/About'
import Services from './Screens/Services'
import Contact from './Screens/Contact'
import Careers from './Screens/Careers';

import Bulkdrugs from './Screens/Gallery/Bulkdrugs';
import Formulation from './Screens/Gallery/Formulation';
import Biotech from './Screens/Gallery/Biotech'
import CleanRooms from './Screens/Gallery/CleanRooms';
import Rdlabs from './Screens/Gallery/Rdlabs'
import Foods from './Screens/Gallery/Foods'
import Officeinter from './Screens/Gallery/Officeinter'
import Associates from './Screens/Associates';
import Projects from './Screens/Projects';
import Jobform from './Screens/Jobform';



function App() {
  return (
    <div className="App">
        <HashRouter>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/About" component={About} />
                <Route path="/Services" component={Services} />
                <Route path="/Contact" component={Contact} />
                <Route path="/Careers" component={Careers} />
                <Route path="/Associates" component={Associates} />
                <Route path="/Projects" component={Projects} />

                <Route path="/Gallery/Bulkdrugs" component={Bulkdrugs} />
                <Route path="/Gallery/Formulation" component={Formulation} />
                <Route path="/Gallery/Biotech" component={Biotech} />
                <Route path="/Gallery/CleanRooms" component={CleanRooms} />
                <Route path="/Gallery/Rdlabs" component={Rdlabs} />
                <Route path="/Gallery/Foods" component={Foods} />
                <Route path="/Gallery/Officeinter" component={Officeinter} />

                <Route path="/Jobform" component={Jobform} />

            </Switch>
        </HashRouter>
    </div>
  );
}

export default App;
