import React, { Component } from 'react'
import Header from '../../../Components/Header'
import Footer from '../../../Components/Footer'
import './bulkdrugs.css'

import bulk1 from '../../../Images/bulk1.png'
import bulk2 from '../../../Images/bulk2.png'
import bulk3 from '../../../Images/bulk3.png'
import bulk4 from '../../../Images/bulk4.png'
import bulk5 from '../../../Images/bulk5.png'
import bulk6 from '../../../Images/bulk6.png'
import bulk7 from '../../../Images/bulk7.png'
import bulk8 from '../../../Images/bulk8.png'
import bulk9 from '../../../Images/bulk9.png'
import bulk10 from '../../../Images/bulk10.png'
import bulk11 from '../../../Images/bulk11.png'
import bulk12 from '../../../Images/bulk12.png'
import bulk13 from '../../../Images/bulk13.png'
import bulk14 from '../../../Images/bulk14.png'
import bulk15 from '../../../Images/bulk15.png'
import bulk16 from '../../../Images/bulk16.png'
import bulk17 from '../../../Images/bulk17.png'
import bulk18 from '../../../Images/bulk18.png'
import bulk19 from '../../../Images/bulk19.png'
import bulk20 from '../../../Images/bulk20.png'
import bulk21 from '../../../Images/bulk21.png'
import bulk22 from '../../../Images/bulk22.png'

class Bulkdrugs extends Component{
    componentDidMount = () =>{
        const lightbox = document.createElement('div')
        lightbox.id = 'lightbox'
        document.body.appendChild(lightbox)

        const images = document.querySelectorAll('#img')
        images.forEach(image => {
            image.addEventListener('click', e => {
                lightbox.classList.add('active')
                const img = document.createElement('img')
                img.src = image.src
                while (lightbox.firstChild) {
                    lightbox.removeChild(lightbox.firstChild)
                }
                lightbox.appendChild(img)
            })
        })
        lightbox.addEventListener('click', e => {
            lightbox.classList.remove('active')
        })
     
    }

    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Gallery-content">
                            <h1>Bulk Drugs</h1>
                            <div className="gallery photos-section" data-lightbox="mygallery">
                                <img src={bulk1} id="img" alt="bulk drugs"/>
                                <img src={bulk2} id="img" alt="bulk drugs"/>
                                <img src={bulk3} id="img" alt="bulk drugs"/>
                                <img src={bulk4} id="img" alt="bulk drugs"/>
                                <img src={bulk5} id="img" alt="bulk drugs"/>
                                <img src={bulk6} id="img" alt="bulk drugs"/>
                                <img src={bulk7} id="img" alt="bulk drugs"/> 
                                <img src={bulk8} id="img" alt="bulk drugs"/>
                                <img src={bulk9} id="img" alt="bulk drugs"/>
                                <img src={bulk10} id="img" alt="bulk drugs"/>
                                <img src={bulk11} id="img" alt="bulk drugs"/>
                                <img src={bulk12} id="img" alt="bulk drugs"/>
                                <img src={bulk13} id="img" alt="bulk drugs"/>
                                <img src={bulk14} id="img" alt="bulk drugs"/>
                                <img src={bulk15} id="img" alt="bulk drugs"/>
                                <img src={bulk16} id="img" alt="bulk drugs"/>
                                <img src={bulk17} id="img" alt="bulk drugs"/>
                                <img src={bulk18} id="img" alt="bulk drugs"/>
                                <img src={bulk19} id="img" alt="bulk drugs"/>
                                <img src={bulk20} id="img" alt="bulk drugs"/>
                                <img src={bulk21} id="img" alt="bulk drugs"/>
                                <img src={bulk22} id="img" alt="bulk drugs"/>
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Bulkdrugs