import React, { Component } from 'react'
import './footer.css'
import FooterVector from '../../Images/FooterVector.svg'
import { Link } from 'react-router-dom'

class Footer extends Component{
    render() {
        return(
            <div className="Footer-section">
                <footer>
                    <div className="footer-content">
                        <div className="row footer-row">
                            <div className="col-md-4 footer-left-vector">
                                <img src={FooterVector} className="footervector" alt="logo"/>
                            </div>

                            <div className="col-md-4 footer-links">
                                <p>
                                    Phone: +91 080 22204636,<br/>
                                    <span className="phone3">+91 080 22284583,<br/></span>
                                    <span className="phone3">+91 080 41131518</span>
                                </p>
                                <p>
                                    E-Mail:<a href="mailto:<nowiki>projects@mjapharmatech.com?" subject="HTML Link" target="_blank"> projects@mjapharmatech.com</a>
                                </p>
                             
                            </div>

                            <div className="col-md-4 address">
                                <div className="address-content">
                                    <p>
                                        #45, 1st floor, 5th Cross, <br/>
                                        8th Main Road,<br/>
                                        Vasanthanagar,<br/>
                                        Bangalore - 560 052<br/>
                                    </p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer

