import React, { Component } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import './jobform.css'


class Jobform extends Component{
    render() {
        return(
            <div id="site-wrapper">
                <Header />
                    <div id="page-content">
                        <div className="Job-application">
                        <form name="Jobapplication" method="post"  data-netlify="true" className="Job-form">
                            <input type="hidden" name="form-name" value="Jobapplication" />
                            <p>
                                Name: <input type="text" name="name"/>
                            </p><br/>
                            <p>
                                Father Name: <input type="text" name="fathername" />
                            </p><br/>
                            <p>
                                Date of Birth: <input type="date" name="date" />
                            </p><br/>
                            <p>
                                Address: 
                                <textarea name="address">

                                </textarea>
                            </p><br/>

                            <p>
                                Email ID: <input type="email" name="email" />
                            </p><br/>

                            <p>
                                Contact Number: <input type="number" name="number" />
                            </p><br/>

                            <p>
                                Highest Qualification: <input type="text" name="Qualification"/>
                            </p><br/>

                            <p>
                                Experience: <input type="text" name="experience" />
                            </p><br/>

                            <p>
                                Resume Title: <input type="file" id="file" name="Resume" placeholder="Choose file"/>
                            </p><br/><br/>

                            <button type="submit" className="btn job-form-btn">Submit</button>
                        </form>
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Jobform