import React, { Component } from 'react'
import './header.css'
import  menubar  from '../../Images/menubar.png';
import { NavLink, Link } from 'react-router-dom'
import mjLogo1 from '../../Images/mjLogo1.svg'
import downloadicon from '../../Images/downloadicon.png'
import companyprofile from '../../files/companyprofile.pdf'

class Header extends Component{
    render() {
        return(
            <div className="Header">
                <nav className="navbar2">
                    <ul>
                        <li>
                            <NavLink to="/Careers" className="main-nav" activeClassName="main-nav-active">Careers</NavLink>
                        </li>

                        <li>
                            <NavLink to="/Contact" className="main-nav" activeClassName="main-nav-active">Contact Us</NavLink>
                        </li>

                        <li>
                            <a href={companyprofile} className="main-nav" activeClassName="main-nav-active" download><img src={downloadicon} className="download-icon" alt="dowload icon"/> Download</a>
                        </li>
                    </ul>
                </nav>
              
                <nav  className="navbar">
                    <a className="navbar-brand" href="#"><img src={mjLogo1} className="mj-logo" alt="Company logo"/></a>
                 
                    <input type="checkbox" id="check" />
                    <label for="check" className="checkbtn">
                        <img src={menubar} className="checkbtn-image" alt="btn"/>
                    </label>
                   
                    <ul>
                        <li>
                            <NavLink exact to="/" className="main-nav" activeClassName="main-nav-active">Home</NavLink>
                        </li>

                        <li>
                            <NavLink to="/About" className="main-nav" activeClassName="main-nav-active">About us</NavLink>
                        </li>

                        <li>
                            <NavLink to="/Services" className="main-nav" activeClassName="main-nav-active">Services</NavLink>
                        </li>

                        <li>
                            <NavLink to="/Associates" className="main-nav" activeClassName="main-nav-active">Directors and Associates</NavLink>
                        </li>

                        <li>
                            <NavLink to="/Projects" className="main-nav" activeClassName="main-nav-active">Clientele</NavLink>
                        </li>

                        <li className="dropdown">
                            <NavLink to="/Bulkdrugs" className="main-nav dropdown-toggle" activeClassName="main-nav-active"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Gallery</NavLink>
                                <div className="dropdown-menu masthead-dropdown" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/Gallery/Bulkdrugs">Bulk Drugs</Link>
                                    <Link className="dropdown-item" to="/Gallery/Formulation">Formulation</Link>
                                    <Link className="dropdown-item" to="/Gallery/Biotech">Biotech</Link>
                                    <Link className="dropdown-item" to="/Gallery/CleanRooms">Clean Rooms</Link>
                                    <Link className="dropdown-item" to="/Gallery/Rdlabs">R & D Labs</Link>
                                    <Link className="dropdown-item" to="/Gallery/Foods">Foods & Confectionery</Link>
                                    <Link className="dropdown-item" to="/Gallery/Officeinter">Office Interiors</Link>
                                </div>
                        </li>

                        <li className="top-links-hide">
                            <NavLink to="/Careers" className="main-nav" activeClassName="main-nav-active">Careers</NavLink>
                        </li>

                        <li className="top-links-hide">
                            <NavLink to="/Contact" className="main-nav" activeClassName="main-nav-active">Contact Us</NavLink>
                        </li>

                        <li className="top-links-hide">
                            <a href={companyprofile} className="main-nav" activeClassName="main-nav-active" download><img src={downloadicon} className="download-icon" alt="dowload icon"/> Download</a>
                        </li>




                        <label for="check" className="hide-menu-btn checkbtn">
                            <i className="fa fa-times"></i>
                        </label>
                    </ul>
                </nav>
            </div>
        );
    }
}

export default Header

